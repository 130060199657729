import {
	useMountEffect,
	getOAuthStatus,
	capitalize,
	expandJsonSize,
	reduceJsonSize,
	REVERSE_STATE_JSON_MAPPING,
	refreshAraAccessToken,
	parseJwt,
} from "libs"

export function OAuthCallback() {
	const query = new URLSearchParams(window.location.search)

	const parent = window.parent as Window
	const rawState = query.get("state")
	const state = expandJsonSize(rawState, REVERSE_STATE_JSON_MAPPING)
	const { platformPageProps, sig, shortId } = state

	useMountEffect(async () => {
		if (platformPageProps?.newTab) {
			let accessToken
			if (sig == null) {
				//get sig from shortId
				;({ accessToken } = await refreshAraAccessToken(shortId))
			}
			// new tab flow
			console.log("sending ready message to oauth channel")
			const authOverride = accessToken ?? sig
			const claims = parseJwt(authOverride)

			getOAuthStatus(
				{
					authCode: query.get("code"),
					platform: claims.platform,
					externalUserId: claims.userId,
					externalClientId: claims.externalClientId,
					clientId: claims.clientId,
				},
				authOverride
			)
				.then((response) => {
					if (response) {
						// success, close the window
						window.close()
					} else {
						throw new Error("Error authorizing Hireara, false value returned from api")
					}
				})
				.catch((e) => {
					console.error(e)
					alert(`Error authorizing Hireara access to ${capitalize(claims.platform)}`)
				})
		} else {
			console.log("sending message to parent")
			parent?.postMessage({ query: Object.fromEntries(query.entries()) }, "*")
		}
	})

	return <div></div>
}
